import React from 'react';
import { Route, HashRouter } from 'react-router-dom';
import "./../styles/global.css";

const HighchartsDemo = React.lazy(() => import('./../components/highchartsDemo'));
const DashBoard = React.lazy(() => import('./../components/DashBoard'));
const datadisplay = React.lazy(() => import('./../components/datadisplay'));

const IndexPage = () => {
  const isSSR = typeof window === "undefined"
  return (
    <>
      {!isSSR && (
        <HashRouter>
        <React.Suspense fallback={<div />}>
          <Route exact path="/" component={ DashBoard } />
          <Route exact path="/highcharts/:type" component={ HighchartsDemo } />
          <Route exact path="/datadisplay" component={ datadisplay } />
        </React.Suspense>
        </HashRouter>
      )}
    </>
  )
}

export default IndexPage;

